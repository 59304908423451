@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,700;1,9..40,400&family=Montserrat:wght@700&family=Poppins:wght@300;400;500;600&display=swap');

*   {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root   {
    --bg-color: #1f1f38;
    --bg-color-variant: #2c2c6c;

    --primary-color: #4db5ff;
    --primary-color-variant: rgba(77, 181, 255, 0.4);

    --white-color: #fff;
    --light-color: rgba(255, 255, 255, 0.6);

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --transition: all 400ms ease;
}

html    {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
   display: none; 
}

body    {
    font-family: "Poppins", sans-serif;
    background: var(--bg-color);
    color: var(--white-color);
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.png);
}

/* ============= GENERAL STYLES ================ */
.container  {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5  {
    font-size: 500;
}

h1  {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, 
section > h5  {
    text-align: center;
    color: var(--light-color);
 
}

section > h2    {
    color: var(--primary-color);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--light-color);
}

a   {
    color: var(--primary-color);
    transition: var(--transition);
}

a:hover {
    color: var(--light-color);
}

.btn    {
    width: max-content;
    display: inline-block;
    color: var(--primary-color);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--primary-color);
    transition: var(--transition);
}

.btn:hover  {
    background: var(--white-color);
    color: var(--bg-color);
    border-color: transparent;
}

.btn-primary    {
    background: var(--primary-color);
    color: var(--bg-color);
    
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ========== MEDIA QUERIES (MEDIUM SIZED DEVICES) ========== */

@media screen and (max-width: 1024px) {
    .container  {
        width: var(--container-width-md);
    }
    
    section {
        margin-top: 6rem;
    }
}

/* ========== MEDIA QUERIES (SMALL SIZED DEVICES) ========== */

@media screen and (max-width: 600px) {
    .container  {
        width: var(--container-width-sm);
    }
    
    section > h2 {
        margin-bottom: 2rem;
    }
}
